<template>
    <div>
        <button @click="oBox()">鱼骨图</button>
        <button @click="oBox1()">资源脑图</button>
        <button @click="oBox2()">创智流程沙盘</button>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        // 鱼骨图
        oBox(){
            this.$router.push({name:'Fishbonediagram'})
        },
        // 资源脑图
        oBox1(){
            this.$router.push({name:'Resourcesfigure'})
        },
        //创智流程沙盘
        oBox2(){
            this.$router.push({name:'Wisdomprocess'})
        },
    },
}
</script>
<style scoped>
button{
    margin-left: 30px;
    margin-top: 50px;
    cursor: pointer;
}
</style>